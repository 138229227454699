import React, { memo, useEffect, useState } from "react";
import "./Header.css";
import MenuBar from "./MenuBar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import SearchBar from "./SearchBar";

function Header() {
  return (
    <>
      <div className="sticky-top bg-body">
        <Container>
          <div className="pt-1 pb-1 row ">
            <div className="text-dark col-lg-2 col d-flex justify-content-between">
              <Link to={"/"}>
                <img
                  src={require("../../images/webLogo.png")}
                  alt=""
                  height="50px"
                />
              </Link>
              <a
                className="d-lg-none"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                {/* <img src={require("../../images/menu.png")} alt="menu" /> */}
                <i
                  className="fa-solid fa-bars pt-3 fs-3"
                  style={{ color: "#212159" }}
                ></i>
              </a>
            </div>
            <div className="col-lg-8 d-none d-lg-block ms-1 ps-2">
              <MenuBar />
            </div>
            {/* <div className="text-end col pt-3">
              <a className="text-dark px-3" href="">
                Login
              </a>
              <a className="text-light bg-theme rounded-1 py-2 px-3" href="">
                Sign up
              </a>
            </div> */}
          </div>
        </Container>

        <SearchBar />
        <div className="container">
          {/* <Breadcrumbs /> */}
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            menui
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Accordion Item #1
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRighta"
                    aria-controls="offcanvasRighta"
                  >
                    This is the first item's accordion body.
                  </strong>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Accordion Item #2
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRighta"
                    aria-controls="offcanvasRighta"
                  >
                    This is the second item's accordion body.
                  </strong>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Accordion Item #3
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRighta"
                    aria-controls="offcanvasRighta"
                  >
                    This is the third item's accordion body.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRighta"
        aria-labelledby="offcanvasRightaLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightaLabel">Submenu Content</h5>
          <a
            type="button"
            className="btn-close text-reset"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            {/* back */}
          </a>
        </div>
        <div className="offcanvas-body">
          ...
          <h2>Menu 2</h2>
        </div>
      </div>
    </>
  );
}

export default memo(Header);
