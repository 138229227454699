import "./AllCollegeFilterPage.css";
import locationImg from "../../images/location-icon-img.png";
import starImg from "../../images/star-icon.png";
import { memo, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  redirect,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import PullToRefresh from "react-pull-to-refresh";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import LeadsGenPopup from "../../components/leadsGenPopup/LeadsGenPopup";

type courseType = {
  courselevelId: number;
  courseLevelName: string;
};
type location = {
  locationId: number;
  cityAttributeJson: '{"attribute8": "value8"}';
  cityName: string;
  country: string;
  displayOrder: number;
  imageUrl: string;
  latitude: number;
  longitude: number;
  pincode: string;
  state: string;
  totalCourses: number;
  totalInstitutes: number;
  trending: number;
};
type ownerShip = {
  ownershipId: number;
  ownershipType: string;
};
type stream = {
  streamId: number;
  streamName: string;
  isMenuShow: number;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  courseCategory: any;
};
type course = {
  courseMasterId: number;
  courseName: string;
  slug: string;
  isMenuShow: number;
  courseDescription: string;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  isTrendingCourse: number;
  courseLevelId: number;
  streamId: number;
  courseCategoryId: number;
};
type examExceptedList = {
  examAcceptedId: number;
  examName: string;
};
type collegeDetails = {
  instituteProfileId: 1;
  name: string;
  slug: string;
  establishmentYear: string;
  ranking: number;
  aboutInstitute: string;
  logo: string;
  location: location;
  averagePackage: string;
  examExceptedList: string[];
  tuitionFees: string;
};

type postFilterJsonData = {
  location: number[];
  ownerShip: number;
  stream: number[];
  course: number[];
  specialization: string;
  courseType: number[];
  examAccepted: number[];
};

function AllCollegeFilterPage() {
  const currentUrl = useLocation();
  const navigate = useNavigate();
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";

  const {
    slug,
    stream,
    course,
    exam,
    specialization,
    city,
    collegesInLocation,
  } = useParams();

  // console.log(
  //   "slug:-" + slug,
  //   "stream:-" + stream,
  //   "course:-" + course,
  //   "exam:-" + exam,
  //   "spec:-" + specialization,
  //   "city:-" + city,
  //   "collegeLoc:-" + collegesInLocation
  // );

  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [searchLocation, setSearchLocation] = useState<string>("");
  const [locations, setLocations] = useState<location[]>();
  const [allLocations, setAllLocations] = useState<location[]>();

  // const [searchOwnership, setSearchOwnership] = useState<string>("");
  const [ownerShip, setOwnership] = useState<ownerShip[]>();
  // const [allOwnership, setAllOwnership] = useState<ownerShip[]>();

  const [searchStream, setSearchStream] = useState<string>("");
  const [streams, setStreams] = useState<stream[]>();
  const [allStreams, setAllStreams] = useState<stream[] | undefined>();

  const [searchCourses, setSearchCourses] = useState<string>("");
  const [courses, setCourses] = useState<course[]>();
  const [allCourses, setAllCourses] = useState<course[]>();

  const programMode = [
    { programModeId: 1, modeType: "Full Time" },
    { programModeId: 2, modeType: "Part Time" },
    { programModeId: 3, modeType: "Online" },
    { programModeId: 4, modeType: "Distance" },
  ];

  const [searchexamAccepted, setSearchexamAccepted] = useState<string>();
  const [examAccepted, setexamAccepted] = useState<examExceptedList[]>();
  const [allexamAccepted, setAllexamAccepted] = useState<examExceptedList[]>();

  const [collegeDetails, setCollegeDetails] = useState<
    collegeDetails[] | null
  >();
  const [pageNo, setPageNo] = useState(1);

  // const [collegeDetailsApi, setCollegeDetailsApi] = useState<string>(
  //   `${baseURL}/instituteProfiles/filter/data?city=pune&city=mumbai&ownership=private&ownership=public&courses=mba&courses=bba&stream=management&examAccepted=cat&_page=${pageNo.toString()}&_size=15`
  // );

  // const url =
  //   "instituteProfiles/filter/data?city=pune&ownership=private&courses=mba&stream=management&examAccepted=cat&_page=1&_size=50";

  const [selectedFilters, setSelectedFilters] = useState({
    location: city ? city : '',
    ownerShip: "",
    stream: stream ? stream : "",
    course: course ? course : "",
    program: "",
    exam: exam ? exam : "",
  })
  
  const [selectedLocation, setSelectedLocation] = useState<string | null>(
    city ? city : ""
  );
  const [selectedOwnership, setSelectedOwnership] = useState<string | null>("");
  const [selectedStream, setSelectedStream] = useState<string | null>(
    stream ? stream : ""
  );
  const [selectedCourse, setSelectedCourse] = useState<string | null>(
    course ? course : ""
  );
  const [selectedProgram, setSelectedProgram] = useState<string | null>("");
  const [selectedExam, setSelectedExam] = useState<string | null>(
    exam ? exam : ""
  );

  if (selectedLocation || city) {
    const isValidCity = locations?.some(
      (loc) => loc.cityName === (selectedLocation || city)
    );
    console.log(city);
    if (!isValidCity) {
      // Redirect to a 404 page if the student is not found
      // navigate('/404');
      console.log("location is not matched");
      console.log(city);
    }
  }

  // Functions run on click any filter
  const locationIsClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      window.history.pushState(
        {},
        "",
        `/colleges/${e.target.value.toLowerCase()}-best-colleges`
      );
      setSelectedLocation(e.target.value);
    } else {
      window.history.pushState(
        {},
        "",
        `/all-college`
      );
      setSelectedLocation("");
    }
  };
  const ownerShipIsClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      setSelectedOwnership(e.target.value);
    } else {
      setSelectedOwnership("");
    }
  };
  const streamIsClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      setSelectedStream(e.target.value);
    } else {
      setSelectedStream("");
    }
  };
  const courseIsClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      setSelectedCourse(e.target.value);
    } else {
      setSelectedCourse("");
    }
  };
  const programIsClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      setSelectedProgram(e.target.value);
    } else {
      setSelectedProgram("");
    }
  };
  const examIsSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollegeDetails(undefined);
    if (e.target.checked) {
      setSelectedExam(e.target.value);
    } else {
      setSelectedExam("");
    }
  };

  // Functions for fetch data
  const fetchAllLocations = async () => {
    try {
      const response = await axios.get(`${baseURL}/locations`);
      setAllLocations(response.data);
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOwnerShip = async () => {
    try {
      const response = await axios.get(`${baseURL}/ownerships`);
      // setAllOwnership(response.data);
      setOwnership(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAllStreams = async () => {
    try {
      const response = await axios.get(`${baseURL}/streams`);
      setAllStreams(response.data);
      setStreams(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${baseURL}/course-masters`);
      setAllCourses(response.data);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchexamAccepted = async () => {
    try {
      const response = await axios.get(`${baseURL}/exam-accepted`);
      setAllexamAccepted(response.data);
      setexamAccepted(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let collegesBySlug = `${baseURL}/get-Institute-bySlug?_page=${pageNo.toString()}&_size=15&searchSlugs=${
    slug ? slug.replaceAll("-", " ") : collegesInLocation?.replaceAll("-", " ")
  }`;
  // let collegesByCity = `${baseURL}/instituteProfiles/filter/data?city=${city}&_page=${pageNo.toString()}&_size=15`;
  // let collegesByStream = `${baseURL}/instituteProfiles/filter/data?stream=${stream}&_page=${pageNo.toString()}&_size=15`;
  // let collegesByCourse = `${baseURL}/instituteProfiles/filter/data?courses=mba&_page=${pageNo.toString()}&_size=15`;
  let collegeProfile = `${baseURL}/instituteProfiles/filter/data?&city=${selectedLocation}&ownership=${selectedOwnership}&courses=${selectedCourse}&stream=${selectedStream}&examAccepted=${selectedExam}&_page=${pageNo.toString()}&_size=15`;
  let collegeProfileApi = `${baseURL}/instituteProfiles?_page=${pageNo.toString()}&_size=15`;

  // fetch colleges according to the url
  const fetchCollegeDetails = async () => {
    setCollegeDetails(null);
    try {
      const response = await axios.get(
        selectedLocation ||
          selectedOwnership ||
          selectedCourse ||
          selectedStream ||
          selectedExam ||
          selectedProgram
          ? collegeProfile
          : slug || collegesInLocation
          ? collegesBySlug
          // : city
          // ? collegesByCity
          // : stream
          // ? collegesByStream
          // : course
          // ? collegesByCourse
          : collegeProfileApi
      );
      const { data } = response;
      if (data.last) {
        setHasMore(false);
      }
      if (data.content.length > 0) {
        if (collegeDetails) {
          setCollegeDetails(collegeDetails?.concat(response.data.content));
        } else {
          setCollegeDetails(response.data.content);
        }
        if (isLoading) {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchInputs = () => {
    if (searchLocation == null || searchLocation === "") {
      setLocations(allLocations);
    } else if (searchLocation) {
      let arr = allLocations?.filter((el) =>
        el.cityName.toLowerCase().includes(searchLocation.toLowerCase())
      );
      setLocations(arr);
    }
    if (searchStream == null || searchStream === "") {
      setStreams(allStreams);
    } else if (searchStream) {
      let arr = allStreams?.filter((el) =>
        el.streamName.toLowerCase().includes(searchStream.toLowerCase())
      );
      setStreams(arr);
    }
    if (searchexamAccepted == null || searchexamAccepted === "") {
      // console.log("examsearchexamAccepted is null...!!")
      setexamAccepted(allexamAccepted);
    } else if (searchexamAccepted) {
      let arr = allexamAccepted?.filter((el) =>
        el.examName.toLowerCase().includes(searchexamAccepted.toLowerCase())
      );
      setexamAccepted(arr);
    }
    if (searchCourses == null || searchCourses === "") {
      // console.log("examsearchexamAccepted is null...!!")
      setCourses(allCourses);
    } else if (searchCourses) {
      let arr = allCourses?.filter((el) =>
        el.courseName.toLowerCase().includes(searchCourses.toLowerCase())
      );
      setCourses(arr);
    }
  };

  // this update the page no on scroll to buttom
  const fetchData = () => {
    setPageNo(pageNo + 1);
    // console.log("page no " + pageNo);
  };

  // when you write in search bar of filters
  useEffect(() => {
    setPageNo(1);
    handleSearchInputs();
  }, [searchLocation, searchStream, searchCourses, searchexamAccepted]);

  useEffect(() => {
    fetchCollegeDetails();
  }, [pageNo]);

  // This code will run whenever the URL changes.
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageNo(1);
    // console.log("URL changed:", currentUrl.pathname);
    setTimeout(function () {
      setCollegeDetails(null);
      fetchCollegeDetails();
    }, 100);
  }, [currentUrl]);

  // This code will run on the first render
  useEffect(() => {
    fetchAllLocations();
    fetchOwnerShip();
    fetchAllStreams();
    fetchexamAccepted();
    fetchCourses();
  }, []);

  useEffect(() => {
    fetchCollegeDetails();
  }, [
    selectedLocation,
    selectedStream,
    selectedCourse,
    selectedExam,
    selectedProgram,
    selectedOwnership,
  ]);

  return (
    <>
      <LeadsGenPopup />
      {/* page No : {pageNo} */}
      <div className="container filter_page">
        {/* <Breadcrumbs /> */}
        {slug ? (
          <h1 className="flc my-4 fs-3 text-theme text-center">
            {slug.replaceAll("-", " ")}
          </h1>
        ) : (
          <h1 className="flc my-4 fs-3 text-theme text-center">
            Top{" "}
            {course && specialization
              ? `${course} in ${specialization}`
              : stream && !(course && specialization)
              ? stream
              : course && !(stream && specialization) && course}{" "}
            Colleges {exam && `acceptes ${exam}`} in{" "}
            {collegesInLocation && !city
              ? collegesInLocation
              : !collegesInLocation && city
              ? city
              : "India"}{" "}
            {new Date().getFullYear()}
            {/*{collegesInLocation}  {stream} {course} {specialization} */}
          </h1>
        )}

        <div className="row my-2">
          <div className="col">
            <button
              className="rounded-pill bg-theme text-light px-3 mb-3 d-block d-lg-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#filterOption"
              aria-controls="filterOption"
            >
              Filter
            </button>
            <div
              className="offcanvas offcanvas-start"
              tabIndex={-1}
              id="filterOption"
              aria-labelledby="filterOptionLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="filterOptionLabel">
                  Filter Colleges
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">Filter options...</div>
            </div>

            <div className="border rounded-1 p-2 d-none d-lg-block">
              <p className="fw-bold rounded-1 bg-light-theme text-theme py-1 px-2 mb-2 align-middle d-flex justify-content-between">
                <span className="py-2">Filters</span>
              </p>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Location:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search..."
                  value={searchLocation}
                  onChange={(e) => setSearchLocation(e.target.value)}
                />
                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow bg-white ps-1">
                    {selectedLocation ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={`${selectedLocation}`}
                          // id={`${loc.locationId}`}
                          onChange={(e) => locationIsClicked(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${selectedLocation}`}
                        >
                          {selectedLocation}
                        </label>
                      </div>
                    ) : (
                      locations?.map((loc) => {
                        return (
                          <div
                            key={loc.locationId + "loc"}
                            className="form-check"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={loc.cityName}
                              id={`${loc.locationId}`}
                              onChange={(e) => locationIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`location-${loc.cityName}`}
                            >
                              {loc.cityName}
                            </label>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="mb-1">Ownership:</p>
                {/* <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                /> */}

                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1">
                    {selectedOwnership ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={selectedOwnership}
                          onChange={(e) => ownerShipIsClicked(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`ownership-${selectedOwnership}`}
                        >
                          {selectedOwnership}
                        </label>
                      </div>
                    ) : (
                      ownerShip &&
                      ownerShip.map((o) => {
                        return (
                          <div key={o.ownershipId} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={o.ownershipType}
                              id={`ownership-${o.ownershipType}`}
                              onChange={(e) => ownerShipIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`ownership-${o.ownershipType}`}
                            >
                              {o.ownershipType}
                            </label>
                          </div>
                        );
                      })
                    )}
                    {/* {ownerShip &&
                      ownerShip.map((o) => {
                        return (
                          <div key={o.ownershipId} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={o.ownershipType}
                              id={`ownership-${o.ownershipType}`}
                              onChange={(e) => ownerShipIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`ownership-${o.ownershipType}`}
                            >
                              {o.ownershipType}
                            </label>
                          </div>
                        );
                      })} */}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Streams:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                  value={searchStream}
                  onChange={(e) => setSearchStream(e.target.value)}
                />

                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1" id="stream">
                    {selectedStream ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={selectedStream}
                          onChange={(e) => streamIsClicked(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${selectedStream}`}
                        >
                          {selectedStream}
                        </label>
                      </div>
                    ) : !stream && streams ? (
                      streams.map((stream) => {
                        return (
                          <div key={stream.streamId} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={stream.streamName}
                              id={`stream-${stream.streamName}`}
                              onChange={(e) => streamIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`location-${stream.streamName}`}
                            >
                              {stream.streamName}
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={stream}
                          id={`stream-${stream}`}
                          defaultChecked
                          // onClick={}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${stream}`}
                        >
                          {stream}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Courses:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                  value={searchCourses}
                  onChange={(e) => setSearchCourses(e.target.value)}
                />
                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1" id="stream">
                    {selectedCourse ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={selectedCourse}
                          onChange={(e) => courseIsClicked(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${selectedCourse}`}
                        >
                          {selectedCourse}
                        </label>
                      </div>
                    ) : (
                      courses &&
                      courses.map((course) => {
                        return (
                          <div
                            key={course.courseMasterId}
                            className="form-check"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={course.courseName}
                              id={`stream-${course.courseName}`}
                              onChange={(e) => courseIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`location-${course.courseName}`}
                            >
                              {course.courseName}
                            </label>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Program Mode:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                />

                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1">
                    {selectedProgram ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={selectedProgram}
                          id="location-full-time"
                          onChange={(e) => programIsClicked(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${selectedProgram}`}
                        >
                          {selectedProgram}
                        </label>
                      </div>
                    ) : (
                      programMode.map((mode, index) => {
                        return (
                          <div key={index} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={mode.modeType}
                              id={`location-full-${mode.programModeId}`}
                              onChange={(e) => programIsClicked(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`location-${mode.programModeId}`}
                            >
                              {mode.modeType}
                            </label>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Exams Accepted:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                  value={searchexamAccepted}
                  onChange={(e) => setSearchexamAccepted(e.target.value)}
                />

                <div className="scrollbar border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1">
                    {selectedExam ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={selectedExam}
                          onChange={(e) => examIsSelected(e)}
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`exam-${selectedExam}`}
                        >
                          {selectedExam}
                        </label>
                      </div>
                    ) : (
                      examAccepted?.map((exam) => {
                        return (
                          <div key={exam.examAcceptedId} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={exam.examName}
                              id={`exam-${exam.examName}`}
                              onChange={(e) => examIsSelected(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`exam-${exam.examName}`}
                            >
                              {exam.examName}
                            </label>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="text-theme py-1 px-2 mb-2">
                <p className="">Course Type:</p>
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  className="mt-1 mb-2 form-control"
                  placeholder="search here..."
                />

                <div className="border rounded-2 filter_scrollbar py-1 px-2">
                  <div className="force-overflow ps-1">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="location-Bachelors"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="location-Bachelors"
                      >
                        Bachelors
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 pt-3 border rounded-1 overflow-auto filter_scrollbar" style={{maxHeight: '1700px'}}>
            <div className="row g-3 pb-5">
              {collegeDetails?.map((college) => (
                <div
                  key={college.instituteProfileId + "clgId"}
                  className="col-md-4 col-sm-6"
                >
                  <div
                    className="college-info-card border rounded-top-1 position-relative"
                    style={{ fontSize: "14px", height: "342px" }}
                  >
                    <img
                      className="w-100"
                      src={require("../../images/college-cover-img.png")}
                      alt="img"
                      height={90}
                    />
                    <div className="bg-theme p-2" style={{ height: "43px" }}>
                      <Link
                        to={`/college/${college.name.replace(/ /g, "-")}/${
                          college.instituteProfileId
                        }/about/#footer`}
                      >
                        <img
                          className="bg-white"
                          src={college.logo ? `http://192.168.1.2:942/cf/photo/institute/${college.logo}` : ''}
                          alt="college-logo"
                          style={{
                            marginTop: "-32px",
                            height: "60px",
                            maxWidth: "100%",
                          }}
                        />
                      </Link>
                    </div>
                    <div className="d-flex">
                      <Link
                        className="w-100"
                        to={`/college/${college.slug}/${college.instituteProfileId}/about`}
                      >
                        <div className="bg-light-theme text-theme w-100 pt-2 px-2 pb-1">
                          <p
                            className="college-name fw-bold"
                            style={{ height: "42px" }}
                          >
                            {college.name.length > 60
                              ? college.name.substring(0, 64) + "..."
                              : college.name}
                          </p>
                          <i
                            className="fa-solid fa-location-dot"
                            style={{ color: "#000000" }}
                          ></i>
                          <small className="ps-1 pe-2">
                            {college.location.cityName} (
                            {college.location.state})
                          </small>
                          <i
                            className="fa-solid fa-star"
                            style={{ color: "#ffc905" }}
                          ></i>
                          <small className="pt-2 ps-1 pe-2">
                            {college.ranking}
                          </small>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="px-2 position-absolute"
                      style={{ bottom: "50px" }}
                    >
                      <table className="">
                        <tbody>
                          <tr>
                            <td>
                              <small>Average Package</small>
                            </td>
                            <td>
                              <small>: {college.averagePackage}</small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Exams Accepted</small>
                            </td>
                            <td>
                              <small>
                                :
                                {college.examExceptedList.join().length > 17
                                  ? college.examExceptedList
                                      .join()
                                      .substring(0, 17) + "..."
                                  : college.examExceptedList.join()}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Total Tuition Fees</small>
                            </td>
                            <td>
                              <small>: {college.tuitionFees}</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex content-fit position-absolute bottom-0 start-0 end-0">
                      <button className="border bg-light text-theme w-50 fw-bold py-2">
                        Apply Now
                      </button>
                      <Link
                        to={"/contact"}
                        className="text-center bg-theme text-light w-50 fw-bold py-2"
                      >
                        Contact Us
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              {isLoading ? (
                <p>loading....</p>
              ) : (
                (collegeDetails == null || collegeDetails.length === 0) && (
                  <p className="p-5">data not found!</p>
                )
              )}
            </div>
            {collegeDetails && (
              // <InfiniteScroll
              //   dataLength={collegeDetails.length} //This is important field to render the next data
              //   next={fetchData}
              //   hasMore={true}
              //   loader={<p></p>}
              //   endMessage={
              //     <p style={{ textAlign: "center" }}>
              //       <b>Yay! You have seen it all</b>
              //     </p>
              //   }
              //   children={undefined} // below props only if you need pull down functionality
              //   // refreshFunction={this.refresh}
              //   // pullDownToRefresh
              //   // pullDownToRefreshThreshold={50}
              //   // pullDownToRefreshContent={
              //   //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              //   // }
              //   // releaseToRefreshContent={
              //   //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              //   // }
              // >
              //   {/* {items} */}
              // </InfiniteScroll>
              <InfiniteScroll
                dataLength={collegeDetails.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<p></p>}
                endMessage={
                  <p className="text-theme pb-3" style={{ textAlign: "center" }}>
                    {/* <b>Yay! You have seen it all</b> */}
                    <b>No more colleges found!</b>
                  </p>
                }
              >
                <div className="row">
                  {/* Your actual content here */}
                  {/* {collegeDetails.map((college, index) => (
                    <div
                      key={college.instituteProfileId + "clgId"}
                      className="col-md-4 col-6"
                    >
                      <div
                        className="college-info-card border rounded-top-1 position-relative"
                        style={{ fontSize: "14px", height: "342px" }}
                      >
                        <img
                          className="w-100"
                          src={require("../../images/college-cover-img.png")}
                          alt="img"
                          height={90}
                        />
                        <div
                          className="bg-theme p-2"
                          style={{ height: "43px" }}
                        >
                          <Link
                            to={`/college/${college.name.replace(/ /g, "-")}/${
                              college.instituteProfileId
                            }/about/#footer`}
                          >
                            <img
                              className="bg-white"
                              src={`http://192.168.1.2:942/cf/photo/institute/${college.logo}`}
                              alt="college-logo"
                              style={{
                                marginTop: "-32px",
                                height: "60px",
                                maxWidth: "100%",
                              }}
                            />
                          </Link>
                        </div>
                        <div className="d-flex">
                          <Link
                            className="w-100"
                            to={`/college/${college.slug}/${college.instituteProfileId}/about`}
                          >
                            <div className="bg-light-theme text-theme w-100 pt-2 px-2 pb-1">
                              <p
                                className="college-name fw-bold"
                                style={{ height: "42px" }}
                              >
                                {college.name.length > 60
                                  ? college.name.substring(0, 64) + "..."
                                  : college.name}
                              </p>
                              <i
                                className="fa-solid fa-location-dot"
                                style={{ color: "#000000" }}
                              ></i>
                              <small className="ps-1 pe-2">
                                {college.location.cityName} (
                                {college.location.state})
                              </small>
                              <i
                                className="fa-solid fa-star"
                                style={{ color: "#ffc905" }}
                              ></i>
                              <small className="pt-2 ps-1 pe-2">
                                {college.ranking}
                              </small>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="px-2 position-absolute"
                          style={{ bottom: "50px" }}
                        >
                          <table className="">
                            <tbody>
                              <tr>
                                <td>
                                  <small>Average Package</small>
                                </td>
                                <td>
                                  <small>: {college.averagePackage}</small>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <small>Exams Accepted</small>
                                </td>
                                <td>
                                  <small>
                                    :
                                    {college.examExceptedList.join().length > 17
                                      ? college.examExceptedList
                                          .join()
                                          .substring(0, 17) + "..."
                                      : college.examExceptedList.join()}
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <small>Total Tuition Fees</small>
                                </td>
                                <td>
                                  <small>: {college.tuitionFees}</small>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex content-fit position-absolute bottom-0 start-0 end-0">
                          <button className="border bg-light text-theme w-50 fw-bold py-2">
                            Apply Now
                          </button>
                          <Link
                            to={"/contact"}
                            className="text-center bg-theme text-light w-50 fw-bold py-2"
                          >
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(AllCollegeFilterPage);
