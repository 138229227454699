import axios from "axios";
import React, { FormEvent, useState } from "react";

type userDetails = {
  newLetterId: number;
  email: string;
  phoneNumber: string;
  course: string;
};

function ContactPage() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";

  const [formData, setFormData] = useState({
    newLetterId: "contactPage",
    email: "",
    phoneNumber: "",
    course: "",
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // console.log(formData);

    // try {
    //   const response = await axios.post(
    //     `${baseURL}/newsletter-subscribe/`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   console.log("POST request successful!", response.data);
    //   // You can handle the response data here
    // } catch (error) {
    //   console.error("POST request error:", error);
    //   // You can handle errors here
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value.length <= 10) {
      setFormData({ ...formData, [name]: value.toString() });
    }
  };
  return (
    <div className="container py-5" style={{maxWidth: '1000px' ,height: "90vh" }}>
      <h1 className="text-center text-theme pb-4">Contact Us</h1>
      <div className="row rounded shadow p-5">
        <p className="fs-4 text-center pb-3 fw-bold">
          Reach out to us for inquiries, counseling, or seamless online college
          applications. We're here to assist you every step of the way.
        </p>
        <div className="col-md-6">
          <form action="" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  aria-label="First name"
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  aria-label="Last name"
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="1234567890"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <label htmlFor="email" className="form-label">
                Email address
              </label> */}
            </div>
            <div className="mb-3">
              {/* <label htmlFor="text_message" className="form-label">
                Example textarea
              </label> */}
              <textarea
                className="form-control"
                id="text_message"
                name="textMessage"
                placeholder="Enter your message"
                // value={formData.textMessage}
                // onChange={handleChange}
                required
                rows={4}
              ></textarea>
            </div>
            <button type="submit" className="btn shadow btn-danger">
              Submit
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <div className="border rounded py-2 px-3">
            <p>
              <span className="fw-bold">Phone: </span>
              <a href="tel:917062733333" className="text-theme">+91 70627 33333</a>
            </p>
            <p>
              <span className="fw-bold">Email: </span>
              <a href="https://info@collegefunda.com" className="text-theme" target="_blank">
                info@collegefunda.com
              </a>
            </p>
            <ul className="d-flex" style={{ listStyle: "none" }}>
              <li
                className="my-3 me-3 text-center bg-light-theme rounded-circle"
                style={{ height: "55px", width: "55px" }}
              >
                <a
                  href="https://www.facebook.com/collegefunda/"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f fa-xl text-theme py-4"></i>
                </a>
              </li>
              <li
                className="my-3 me-3 text-center bg-light-theme rounded-circle"
                style={{ height: "55px", width: "55px" }}
              >
                <a
                  href="https://www.linkedin.com/in/college-funda-53511312b/"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin-in fa-xl text-theme py-4"></i>
                </a>
              </li>
              <li
                className="my-3 me-3 text-center bg-light-theme rounded-circle"
                style={{ height: "55px", width: "55px" }}
              >
                <a href="https://twitter.com/collegefundacom" target="_blank">
                  <i className="fa-brands fa-twitter fa-xl text-theme py-4"></i>
                </a>
              </li>
              <li
                className="my-3 me-3 text-center bg-light-theme rounded-circle"
                style={{ height: "55px", width: "55px" }}
              >
                <a
                  href="https://www.instagram.com/collegefunda/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram fa-xl text-theme py-4"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContactPage);
