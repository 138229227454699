import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

function Counseling() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { exam_id } = useParams();
  // console.log(exam_id);
  const [admidcard, setAdmidcard] = useState();

  // https://devsahyogapi.shreecement.com/app/users/login/authenticate

  const fetchAdmidcard = async () => {
    try {
      const response = await axios.post(
        `http://223.239.159.251:9090/users/login/authenticate`,
        {'emailId': "a11#mailinator.com",
          'password' : "anurag"},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAdmidcard();
  }, []);
  return (
    <div>
      counseling
    </div>
  )
}

export default Counseling
