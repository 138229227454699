import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type companyMasterList = {
  companyMasterId: number;
  companyName: string;
  logo: string;
};

type placement = {
  placementId: number;
  lowestPackage: number;
  avgPackage: number;
  highestPackage: number;
  placementHighlight: string;
  companyMasterDTOList: companyMasterList[];
  noOfRecruiter: number;
  noOfOffers: number;
  noOfInternationalOffer: number;
};

function CollegePlacement() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const { college_name } = useParams();
  const [placement, setPlacement] = useState<placement>();

  const fetchPlacementData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles/institute-placement/${institute_id}`
      );
      setPlacement(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const mainData =
    "IIT Madras placements are often considered as some of the best placements in the country. More than 480 companies have participated in the latest Indian Institute of Technology Madras placements, 2022-23, and offered 1612 placement offers. The IIT Madras highest package offered during this drive is INR 1.31 Crores per annum. The highest package was offered to the students of B.Tech in Computer Science and Engineering. Visit the link to check the DoMS IIT Madras placements.     Leading companies like Microsoft, Google, Goldman Sachs, Adobe, Qualcomm, Oracle, Texas Instruments, Bajaj, Quadaye, Walmart, American Express, Uber, etc. are some of the IIT Madras top recruiters. The institute is yet to release placement data like IIT Madras average package, number of students placed, etc.";

  useEffect(() => {
    fetchPlacementData();
  }, []);

  return (
    <>
      {(placement?.placementHighlight != "") && 
        <MainDescription data={placement?.placementHighlight} />
      }
      <div className="college_highlights py-3">
        <p className="title text-theme">Placement Record</p>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Particular</th>
              <th scope="col">Placement Data</th>
            </tr>
          </thead>
          {placement && (
            <tbody>
              <tr>
                <td>Lowest Package Package</td>
                <td>{placement.lowestPackage} LPA</td>
              </tr>
              <tr>
                <td>Average Package</td>
                <td>{placement.avgPackage} LPA</td>
              </tr>
              <tr>
                <td>Highest Package</td>
                <td>{placement.highestPackage} LPA</td>
              </tr>
              <tr>
                <td>No of Recruiter</td>
                <td>{placement.noOfRecruiter}</td>
              </tr>
              <tr>
                <td>No. of International Offer</td>
                <td>{placement.noOfInternationalOffer}</td>
              </tr>
              <tr>
                <td>Total No. of Offers</td>
                <td>{placement.noOfOffers}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <div className="college_highlights py-3">
        <p className="title text-theme">Frequent Companies</p>
        {placement?.companyMasterDTOList.map((company:companyMasterList) => {
          return(
            <img
              key={company.companyMasterId}
              className="p-2"
              src={`http://192.168.1.2:942/cf/photo/CompanyMaster/${company.logo}`}
              alt="company-Logo"
              style={{
                // marginTop: "-50px",
                maxWidth: "150px",
                maxHeight: "60px",
              }}
            />
          )
        })}
      </div>
    </>
  );
}

export default React.memo(CollegePlacement);
