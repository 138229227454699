import React, { useState } from "react";
import "./MainDescription.css";

type MainDescriptionProps = {
  heading?: any;
  data?: string | undefined;
};

function MainDescription(props: MainDescriptionProps) {
  const content = props.data;
  const content2 = "<h1 class='fs-6'>anurag sanadhya</h1>";
  const [showMore, setShowMore] = useState<boolean>(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div className="pt-3">
        <h2 className="title text-theme pb-2">{props.heading}</h2>
        <div
          className={`${
            showMore === false && content && content.length > 300
              ? "fade-text pb-2"
              : ""
          }`}
        >
          {/* <p
            className={`content mb-0 ${
              showMore === false ? "discHeight pb-3 overflow-hidden" : ""
            }`}
            style={{ textAlign: "justify" }}
          >
            {content}
          </p> */}
          {content && (
            <div
              className={`content mb-0 ${
                showMore === false ? "discHeight pb-3 overflow-hidden" : ""
              }`}
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        {content && content.length > 300 && (
          <p
            className={`toggle_content pe-auto ${showMore === false ? "" : ""}`}
            onClick={() => handleShowMore()}
          >
            <a className="text-theme fw-bolder">
              {showMore ? "Read Less" : "Read More"}
            </a>
          </p>
        )}
      </div>
    </>
  );
}

export default MainDescription;
