import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type cutoff = {
  examCutOffId: number;
  title: string;
  slug: string;
  details: string;
  metaTag: string;
  metaTitle: string;
  metaDescription: string;
  examMasterId: number;
};

function Cutoff() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { exam_id } = useParams();
  const [cutoff, setCutoff] = useState<cutoff>();

  const fetchCutoff = async () => {
    try {
      const response = await axios.get(`${baseURL}/exams/cutOff/${exam_id}`);
      setCutoff(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCutoff();
  }, []);

  return (
    <>
      {cutoff && (
        <div className="">
          <MainDescription heading={cutoff.title} data={cutoff.details} />
        </div>
      )}
    </>
  );
}

export default memo(Cutoff);
