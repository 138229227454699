import React from 'react'

function Result() {
  return (
    <div>
      Result
    </div>
  )
}

export default Result
