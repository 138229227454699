import "./LeadsGenPopup.css";
import axios, { AxiosError } from "axios";
import { FormEvent, useEffect, useRef, useState } from "react";

type userDetails = {
  studentCounsellingId: number;
  studentName: string;
  email: string;
  phoneNumber: string;
  backGround: string;
  message: string;
};

function LeadsGenPopup() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  // console.log(window.location.pathname); ///college/sangam-university-su-bhilwara/438/about
  // console.log(window.location.href); //http://localhost:3000/college/sangam-university-su-bhilwara/438/about

  const [formData, setFormData] = useState({
    studentName: "",
    email: "",
    phoneNumber: "",
    backGround: "",
    message: "",
    url: window.location.pathname,
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    // console.log("hangle change..." + name + " " + value);
    setFormData({ ...formData, [name]: value.toString() });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${baseURL}/student/lead-generation`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle successful response
      // console.log("Status:- " + response);
    } catch (error: any) {
      // Handle AxiosError
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(
            "Server responded with error:",
            axiosError.response.status
          );
          console.error("Error data:", axiosError.response.data);
        } else if (axiosError.request) {
          // The request was made but no response was received
          console.error("No response received from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", axiosError.message);
        }
      } else {
        // Handle other types of errors
        console.error("Non-Axios error:", error.message);
      }
    }
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClickWithDelay = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const popup = () => {
    // Trigger button click after 10000 milliseconds (10 seconds)
    const timeoutId = setTimeout(handleClickWithDelay, 5000);
  };

  useEffect(() => {
    popup();
    // document.getElementById('myModal').modal({ show: false})
  }, []);

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <button
        ref={buttonRef}
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#LeadGenPopup"
        style={{ visibility: "hidden" }}
      >
        show popup
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="LeadGenPopup"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="row">
              <div className="col bg-light d-none d-md-block text-center align-buttom">
                <div className="p-3">
                  <img
                    src={require("../../images/registerNow.jpg")}
                    alt="image"
                  />
                </div>
              </div>
              <div className="col">
                <div className="modal-header">
                  <h3
                    className="modal-title fs-2 text-theme"
                    id="exampleModalLabel"
                  >
                    Register Now
                    <i className="fa-solid fa-pen-clip px-2"></i>
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Name:
                    </label>
                    <input
                      type="text"
                      className="form-control mt-0"
                      placeholder="Enter Your name"
                      name="studentName"
                      value={formData.studentName}
                      onChange={handleChange}
                      required
                    />
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email:
                    </label>
                    <input
                      type="email"
                      className="form-control mt-0"
                      placeholder="Enter Your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Number:
                    </label>
                    <input
                      type="number"
                      className="form-control mt-0"
                      placeholder="Enter Your Number"
                      name="phoneNumber"
                      minLength={10}
                      // max={999999999}
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                    />
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Background:
                    </label>
                    <input
                      type="text"
                      className="form-control mt-0"
                      placeholder="Enter Your Background"
                      name="backGround"
                      value={formData.backGround}
                      onChange={handleChange}
                      required
                    />
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Message:
                    </label>
                    <input
                      type="text"
                      className="form-control mt-0"
                      placeholder="Enter Your message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                    {/* <textarea
                          className="form-control mt-0"
                          placeholder="Enter Your message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                          id="exampleFormControlTextarea1"
                          rows={3}
                        ></textarea> */}
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-danger text-light fw-bold mt-3"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadsGenPopup;
