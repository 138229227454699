import axios from "axios";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type admissionData = {
  instituteAdmissionFormId: number;
  description: string;
  titles: string;
  modeOfStudy: string;
  programOffer: string;
  admissionProcessStatus: string;
  howToApply: string;
  levelOfProgrammeOffered: string;
  popularPrograms: string;
  instituteProfileId: number;
  formLink: string;
};

function CollegeAdmission() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [admissionData, setAdmissionData] = useState<admissionData>();

  const fetchAdmissionData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles/admission-details/${institute_id}`
      );
      setAdmissionData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAdmissionData();
  }, []);

  return (
    <>
      {admissionData?.description && (
        <MainDescription
          heading={admissionData?.titles}
          data={admissionData?.description}
        />
      )}
      <div className="college_highlights py-3">
        <p className="title text-theme">Latest Updates of 2023</p>
        <p className="mb-1">
          PhD applications 2024 are open and the last date to apply is Jan 17,
          2024. A few more updates of IIM Ahmedabad are as follows:
        </p>
        <ul className="ps-4">
          <li>The CAT 2023 exam will be held on Nov 26, 2023.</li>
          <li>
            MBA-PGPX R2 applications are open and the last date to apply is Oct
            30, 2023.
          </li>
          <li>
            ePGD-ABA applications are open for R1 and the last date to apply is
            Oct 30, 2023.
          </li>
        </ul>
      </div>
      <div className="py-4">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Parameter</th>
              <th scope="col">Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mode of study</td>
              <td>{admissionData?.modeOfStudy}</td>
            </tr>
            <tr>
              <td>Programmes Offered</td>
              <td>{admissionData?.programOffer}</td>
            </tr>
            <tr>
              <td>Admission status 2024</td>
              <td>{admissionData?.admissionProcessStatus}</td>
            </tr>
            <tr>
              <td>How to apply?</td>
              <td>{admissionData?.howToApply}</td>
            </tr>
            <tr>
              <td>Level of programes offered</td>
              <td>{admissionData?.levelOfProgrammeOffered}</td>
            </tr>
            <tr>
              <td>Popular programmes</td>
              <td>{admissionData?.popularPrograms}</td>
            </tr>
            <tr>
              <td>Download Now</td>
              <td>{admissionData?.formLink}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default memo(CollegeAdmission);
