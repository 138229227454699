import { useEffect, useState } from "react";
import TopColleges from "../../../components/topColleges/TopColleges";
import CollegeFAQs from "./CollegeFAQs";
import MainDescription from "../../../components/mainDescription/MainDescription";
import CollegeHostel from "./CollegeHostel";
import CollegeCoursesFee from "./CollegeCourses&Fee";
import { useLocation } from "react-router-dom";

type location = {
  locationId: number;
  cityAttributeJson: string;
  cityName: string;
  country: string;
  displayOrder: number;
  imageUrl: null;
  latitude: number;
  longitude: number;
  pincode: string;
  state: string;
  totalCourses: number;
  totalInstitutes: number;
  trending: number;
};

type examExcepted = {
  examAcceptedId: number;
  examName: string;
  instituteCourseId: number;
};

type instituteDetail = {
  instituteProfileId: 0;
  name: string;
  shortName: string;
  slug: string;
  establishmentYear: string;
  averagePackage: string;
  tuitionFees: string;
  address1: string;
  address2: string;
  applicationFromFee: string;
  discountOnApplicationFee: string;
  website: string;
  googleMapUrl: string;
  ranking: 0;
  trending: 0;
  showInSuggestion: 0;
  featureCollege: 0;
  published: 0;
  isCaf: 0;
  applicationForm: string;
  ebrochure: string;
  aboutInstitute: string;
  highLights: string;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  videoLink: string;
  relCanonical: string;
  schema: string;
  logo: string;
  metaImage: string;
  locations: {
    locationId: 0;
    cityAttributeJson: string;
    cityName: string;
    country: string;
    displayOrder: 0;
    imageUrl: string;
    latitude: 0;
    longitude: 0;
    pincode: string;
    state: string;
    totalCourses: 0;
    totalInstitutes: 0;
    trending: 0;
  };
  ownership: {
    ownershipId: 0;
    ownershipType: string;
  };
  instituteGroup: {
    instituteGroupId: 0;
    groupName: string;
  };
  affiliatedBy: string;
  accreditationBy: string;
  examExcepted: [
    {
      examAcceptedId: 0;
      examName: string;
      instituteCourseId: 0;
    }
  ];
  facility: {
    facilityId: 0;
    wifi: 0;
    library: 0;
    canteen: 0;
    cafeteria: 0;
    placement: 0;
    sap: 0;
    internationalTour: 0;
    erp: 0;
    uniform: 0;
    languages: 0;
    industrialVisit: 0;
    certificate: 0;
    laptop: 0;
    personalityDevelopment: 0;
    scholarship: 0;
    hostel: 0;
    conveyance: 0;
    loans: 0;
    internship: 0;
    scienceLab: 0;
    computerLab: 0;
    sports: 0;
    auditorium: 0;
    gym: 0;
    amphitheatre: 0;
    medical: 0;
    stationery: 0;
    furnished: 0;
    food: 0;
    tv: 0;
    saloon: 0;
    parking: 0;
    girlsHostel: 0;
    boysHostel: 0;
    antiRagging: 0;
    recordingStudio: 0;
    swimmingPool: 0;
    foodLab: 0;
    eclass: 0;
  };
  hostel: {
    hostelId: 0;
    hostelName: string;
    aboutHostel: string;
    isEnable: 0;
    accommodation: 0;
    bedding: 0;
    furniture: 0;
    bathroomFacilities: 0;
    commonAreas: 0;
    kitchenAndDining: 0;
    laundryFacilities: 0;
    wifi: 0;
    security: 0;
    cleaningAndMaintenance: 0;
    studyRooms: 0;
    recreationFacilities: 0;
    gymOrFitnessCenter: 0;
    access24x7: 0;
    hostelWardens: 0;
    canteenOrSnackShops: 0;
    guestRooms: 0;
    parkingFacilities: 0;
    bicycleStorage: 0;
    culturalAndRecreationalEvents: 0;
    stationery: 0;
  };
};

function CollegeInfo() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [data, setData] = useState<instituteDetail>();
  const [collegeInfo, setCollegeInfo] = useState<string | null>();

  useEffect(() => {
    // This code will run whenever the URL changes.
    window.scrollTo(0, 0);
    // console.log("URL changed:", location.pathname);
    setTimeout(function () {
      console.log("Hello from setTimeout");
      setCollegeInfo(localStorage.getItem("collegeInfo"));
      setIsLoading(false);
    }, 1000);
  }, [location]);

  useEffect(() => {
    // console.log("college info data ..!!");
    if (collegeInfo && collegeInfo !== undefined) {
      setData(JSON.parse(collegeInfo));
    }
  }, [collegeInfo]);

  // useEffect(() => {
  //   console.log("Name: " + data?.name);
  // }, [data]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      {isLoading && <p className="text-center p-5">Loading...</p>}
      {data?.aboutInstitute && (
        <MainDescription
          heading={`About ${data?.name}`}
          data={data?.aboutInstitute}
        />
      )}

      <div className="college_highlights py-3">
        <p className="title text-theme">Highlight of {data?.name}</p>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Parameter</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Established</td>
              <td>{data?.establishmentYear}</td>
            </tr>
            <tr>
              <td>Exam</td>
              <td>
                {data?.examExcepted.map((exam: examExcepted) => {
                  return (
                    <span key={exam.examAcceptedId}>{exam.examName}, </span>
                  );
                })}
              </td>
            </tr>
            <tr>
              <td>Courses</td>
              <td>{data?.examExcepted.length} Courses</td>
            </tr>
            {data?.ownership && (
              <tr>
                <td>Institute Type</td>
                <td>{data?.ownership?.ownershipType}</td>
              </tr>
            )}
            <tr>
              <td>Accreditations</td>
              <td>{data?.accreditationBy}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <CollegeCoursesFee /> */}
      {/* <CollegeHostel />
      <CollegeFAQs /> */}
      {/* <TopColleges page={"college-page"} /> */}
    </>
  );
}

export default CollegeInfo;
