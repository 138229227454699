import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router";
import MainDescription from "../../../components/mainDescription/MainDescription";

type eligibility = {
  eligibilityCriteria: number;
  description: string;
  latestExamUpdate: string;
  nationality: string;
  domicileDetails: string;
  minimumAgeCriteria: string;
  maximumAgeCriteria: string;
  minimumQualification: string;
  maxNumberOfAttempt: string;
  documentRequired: string;
  eligibilityByPercentage: {
    examEligibilityByPercentageId: number;
    description: string;
    genRequiredPercentage: string;
    scRequiredPercentage: string;
    stRequiredPercentage: string;
    obcRequiredPercentage: string;
    otherCategoryRequired: string;
    examMasterId: number;
    phrequiredPercentage: string;
  };
  eligibilityByNationality: {
    examEligibilityByNationalityId: number;
    description: string;
    type1: string;
    type2: string;
    type3: string;
    type4: string;
    type5: string;
  };
  eligibilityByAge: {
    examEligibilityByAge: number;
    description: string;
    additionalNote: string;
    upperAgeLimit: string;
    lowerAgeLimit: string;
    ageRelaxationForReserved: string;
  };
  eligibilityByReservation: {
    examEligibilityByReservationId: number;
    description: string;
    scReservationPercentage: string;
    stReservationPercentage: string;
    obcReservationPercentage: string;
    otherCategoryReservation: string;
    phreservationPercentage: string;
  };
  eligibilityByEducation: {
    eligibilityCriteriaByEducationId: number;
    tenPlus2Qualification: string;
    ineligibleCandidatesDetails: string;
    heading: string;
    description: string;
  };
};

function Eligibility() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { exam_id } = useParams();
  const [eligibility, setEligibility] = useState<eligibility>();

  const fetchEligibility = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/exams/eligibility/${exam_id}`
      );
      setEligibility(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEligibility();
  }, []);

  return (
    <>
      {eligibility && (
        <>
          <MainDescription
            heading={"Discription"}
            data={eligibility.description}
          />
          <table className="table table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col">Details</th>
                <th scope="col">Descriptions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Latest Exam Update</td>
                <td>{eligibility.latestExamUpdate}</td>
              </tr>
              <tr>
                <td>Nationality</td>
                <td>{eligibility.nationality}</td>
              </tr>
              <tr>
                <td>Domilicile Details</td>
                <td>{eligibility.domicileDetails}</td>
              </tr>
              <tr>
                <td>Min. Age Criteria</td>
                <td>{eligibility.minimumAgeCriteria}</td>
              </tr>
              <tr>
                <td>Max. Age Criteria</td>
                <td>{eligibility.minimumAgeCriteria}</td>
              </tr>
              <tr>
                <td>Max. Attempts</td>
                <td>{eligibility.maxNumberOfAttempt}</td>
              </tr>
              <tr>
                <td>Min Qualification</td>
                <td>{eligibility.minimumQualification}</td>
              </tr>
              <tr>
                <td>Document Required</td>
                <td>{eligibility.documentRequired}</td>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <div className="col-md-6">
              <MainDescription
                heading={"Eligibility By Percentage"}
                data={eligibility.eligibilityByPercentage.description}
              />
              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">For</th>
                    <th scope="col">Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>General</td>
                    <td>
                      {
                        eligibility.eligibilityByPercentage
                          .genRequiredPercentage
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>OBC</td>
                    <td>
                      {
                        eligibility.eligibilityByPercentage
                          .obcRequiredPercentage
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>PH</td>
                    <td>
                      {eligibility.eligibilityByPercentage.phrequiredPercentage}
                    </td>
                  </tr>
                  <tr>
                    <td>SC</td>
                    <td>
                      {eligibility.eligibilityByPercentage.scRequiredPercentage}
                    </td>
                  </tr>
                  <tr>
                    <td>ST</td>
                    <td>
                      {eligibility.eligibilityByPercentage.stRequiredPercentage}
                    </td>
                  </tr>
                  <tr>
                    <td>Others</td>
                    <td>
                      {
                        eligibility.eligibilityByPercentage
                          .otherCategoryRequired
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <MainDescription heading={"Eligibility By Reservation"} data={eligibility.eligibilityByReservation.description} />
              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Reservation For</th>
                    <th scope="col">Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SC Reservation Percentage</td>
                    <td>{eligibility.eligibilityByReservation.scReservationPercentage}</td>
                  </tr>
                  <tr>
                    <td>ST Reservation Percentage</td>
                    <td>{eligibility.eligibilityByReservation.stReservationPercentage}</td>
                  </tr>
                  <tr>
                    <td>PH Reservation Percentage</td>
                    <td>
                      {eligibility.eligibilityByReservation.phreservationPercentage}
                    </td>
                  </tr>
                  <tr>
                    <td>OBC Reservation Percentage</td>
                    <td>
                      {eligibility.eligibilityByReservation.obcReservationPercentage}
                    </td>
                  </tr>
                  <tr>
                    <td>Other Reservation Percentage</td>
                    <td>
                      {eligibility.eligibilityByReservation.otherCategoryReservation}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <MainDescription
                heading={"Eligibility By Education"}
                data={eligibility.eligibilityByAge.description}
              />

              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">For</th>
                    <th scope="col">Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Upper Age Limit</td>
                    <td>{eligibility.eligibilityByAge.upperAgeLimit}</td>
                  </tr>
                  <tr>
                    <td>Lower Age Limit</td>
                    <td>{eligibility.eligibilityByAge.lowerAgeLimit}</td>
                  </tr>
                  <tr>
                    <td>Age Relaxation For Reserved</td>
                    <td>
                      {eligibility.eligibilityByAge.ageRelaxationForReserved}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <MainDescription heading={"Eligibility By Reservation"} data={eligibility.eligibilityByReservation.description} />

            </div>
          </div>
        </>
      )}
    </>
  );
}

export default memo(Eligibility);
