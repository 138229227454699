import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

function Feedback() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [admissionData, setAdmissionData] = useState<any>();

  const formData = {
    "userFeedbackId": 0,
    "email": "string",
    "userExperience": "string",
    "name": "string",
    "message": "string"
  }

  const fetchFeedbackData = async () => {
    // e.preventDefault();

    try {
      const response = await axios.post(
        `${baseURL}/student/lead-generation`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle successful response
      // console.log("Status:- " + response);
    } catch (error: any) {
      // Handle AxiosError
    //   if (axios.isAxiosError(error)) {
    //     const axiosError = error as AxiosError;
    //     if (axiosError.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       console.error(
    //         "Server responded with error:",
    //         axiosError.response.status
    //       );
    //       console.error("Error data:", axiosError.response.data);
    //     } else if (axiosError.request) {
    //       // The request was made but no response was received
    //       console.error("No response received from server");
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.error("Error setting up the request:", axiosError.message);
    //     }
    //   } else {
        // Handle other types of errors
        console.error("Non-Axios error:", error.message);
    //   }
    }
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  return <div>feedback</div>;
}

export default Feedback;
