import React, { memo } from "react";

function ExamDate() {
  const handleButtonClick = () => {
    // Change the URL without triggering a page reload
    window.history.pushState({}, "", "/new-url");

    // You might want to perform additional actions here
    // For example, fetch data based on the new URL or update the component state
  };
  return (
    <div>
      Exam date
      <button onClick={handleButtonClick}>Change URL</button>
    </div>
  );
}

export default memo(ExamDate);
