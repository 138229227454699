import "./CollegePage.css";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import collegeImg from "../../images/college-main-img.png";
import FeaturedColleges from "../../components/featuredColleges/FeaturedColleges";
import LeadsGenPopup from "../../components/leadsGenPopup/LeadsGenPopup";

type location = {
  locationId: number;
  cityAttributeJson: string;
  cityName: string;
  country: string;
  displayOrder: number;
  imageUrl: null;
  latitude: number;
  longitude: number;
  pincode: string;
  state: string;
  totalCourses: number;
  totalInstitutes: number;
  trending: number;
};

type examExcepted = {
  examAcceptedId: number;
  examName: string;
  instituteCourseId: number;
};

type instituteDetail = {
  instituteProfileId: number;
  name: string;
  shortName: string;
  slug: string;
  establishmentYear: string;
  averagePackage: string;
  tuitionFees: string;
  address1: string;
  address2: string;
  applicationFromFee: string;
  discountOnApplicationFee: string;
  website: string;
  googleMapUrl: string;
  ranking: number;
  trending: number;
  showInSuggestion: number;
  featureCollege: number;
  published: number;
  isCaf: number;
  applicationForm: null;
  ebrochure: string;
  aboutInstitute: string;
  highLights: string;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  videoLink: string;
  relCanonical: string;
  schema: string;
  logo: string;
  metaImage: string;
  locations: location;
  ownership: any;
  instituteGroup: {
    instituteGroupId: number;
    groupName: string;
  };
  affiliatedBy: string;
  accreditationBy: string;
  examExcepted: examExcepted[];
  facility: null;
};

function CollegePage() {
  const location = useLocation();

  useEffect(() => {
    // This code will run whenever the URL changes.
    // console.log("College page :", location.pathname);
    fetchInstituteDetails();
  }, [location]);

  // localStorage.removeItem("collegeInfo");
  const { institute_id } = useParams();
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const [instituteDetail, setInstituteDetail] = useState<instituteDetail>();

  const fetchInstituteDetails = async () => {
    if (localStorage.getItem("collegeInfo")) {
      localStorage.removeItem("collegeInfo");
    }
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles/complete/${institute_id}`
      );
      setInstituteDetail(response.data);
      localStorage.setItem("collegeInfo", JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("collegeInfo", JSON.stringify(instituteDetail));
  }, [instituteDetail]);

  useEffect(() => {
    // console.log("college page is loaded");
    fetchInstituteDetails();
  }, []);

  return (
    <>
      <LeadsGenPopup />
      <div className="container clg_page_header border-0 my-3">
        <div className="bg-theme border-0 rounded">
          <div className="row">
            <div className="col-12 rounded">
              <img
                src={collegeImg}
                alt="College Image"
                width="100%"
                // height="200px"
              />
            </div>
          </div>
          <div className="row border-0 rounded">
            <div className="col-2 rounded clg_logo_div text-center pt-4 ps-5">
              <img
                className="bg-light p-2"
                src={`http://192.168.1.2:942/cf/photo/institute/${instituteDetail?.logo}`}
                alt="College Logo"
                style={{
                  marginTop: "-50px",
                  maxWidth: "100%",
                  maxHeight: "70px",
                }}
              />
              <p className="text-light mb-0">
                Estd. : {instituteDetail?.establishmentYear}
              </p>
              <p className="text-light mb-0">
                {/* Type: {instituteDetail?.ownership?.ownershipType} */}
              </p>
            </div>
            <div className="col text-light px-3 py-2">
              <h1 className="clg_full_name pt-1 mb-2 fs-3">
                {instituteDetail?.name}
              </h1>
              <p className="clg_location_rating m-0">
                <a href={`${instituteDetail?.googleMapUrl}`}>
                  <i
                    className="fa-solid fa-location-dot pe-1"
                    style={{ color: "#ffffff" }}
                  ></i>
                  <span className="pe-2 text-light">
                    {instituteDetail?.locations?.cityName},{" "}
                    {instituteDetail?.locations?.state} (
                    {instituteDetail?.locations?.pincode})
                  </span>
                </a>
                <i
                  className="fa-solid fa-star pe-1"
                  style={{ color: "yellow" }}
                ></i>
                <span className="pe-2">4.6 (100+ Review)</span>
              </p>
              <p className="mb-0">
                Affiliated By: {instituteDetail?.affiliatedBy}
              </p>
              <p className="mb-0">
                Accrediated By: {instituteDetail?.accreditationBy}
              </p>
            </div>
            <div className="col-md-3 px-3 py-2">
              <div className="row">
                <div className="col-md-12 col text-center">
                  <button className="bg-danger text-light m-1 p-1 border-0 rounded-1" style={{width: '100px'}}>
                    Brochure
                  </button>
                </div>
                <div className="col-md-12 col text-center">
                  <button className="bg-danger text-light m-1 p-1 border-0 rounded-1" style={{width: '100px'}}>
                    Apply
                  </button>
                </div>
                <div className="col-md-12 col text-center">
                  <button className="bg-danger text-light m-1 p-1 border-0 rounded-1" style={{width: '100px'}}>
                    Contact
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container clg_page_body my-3">
        <div className="row">
          <div className="col-12">
            <nav className="nav px-2 border border-2 rounded-1">
              <NavLink
                className="nav-link"
                to="about"
                onClick={() => fetchInstituteDetails()}
              >
                College Info
              </NavLink>
              <NavLink className="nav-link" to="coursesandfees">
                Courses & Fees
              </NavLink>
              <NavLink className="nav-link" to="faculty">
                Faculty
              </NavLink>
              <NavLink className="nav-link" to="admissions">
                Admissions
              </NavLink>
              <NavLink className="nav-link" to="placements">
                Placements
              </NavLink>
              <NavLink className="nav-link" to="facility">
                Facilities
              </NavLink>
              <NavLink className="nav-link" to="hostel">
                Hostel
              </NavLink>
              {/* <NavLink className="nav-link" to="reviews">
                Reviews
              </NavLink> */}
              <NavLink className="nav-link" to="scholarships">
                Scholarships
              </NavLink>
              <NavLink className="nav-link" to="faqs">
                FAQs
              </NavLink>
            </nav>
          </div>
          <div className="col-md-8">
            <Outlet />
          </div>

          <div className="col-md-4 pt-1">
            <div className="campus_view rounded shadow mt-3 p-3">
              <span>Campus View</span>
              <span className="view_all" style={{ float: "right" }}>
                View All
              </span>
              <div
                id="carouselExampleControls"
                className="carousel slide pt-3"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={require("../../images/colleges/college (1).png")}
                      className="d-block w-100"
                      alt="img1"
                      height="230px"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={require("../../images/colleges/college (2).png")}
                      className="d-block w-100"
                      alt="img2"
                      height="230px"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={require("../../images/colleges/college (3).png")}
                      className="d-block w-100"
                      alt="img3"
                      height="230px"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="new_updates rounded shadow mt-3 p-3">
              <p className="mb-0">New Updates</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <FeaturedColleges />
      </div>
    </>
  );
}

export default CollegePage;
